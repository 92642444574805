#datenschutz {
    h2 {
        padding-top: 2rem;
    }

    h3 {
        padding-top: 1.5rem;
    }

    h4 {
        padding-top: 1rem;
    }

    padding-bottom: 2rem;
}