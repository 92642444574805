/* Container für die Animation */
.arrow-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Pfeil-Stil */
  .arrow-icon {
    visibility: hidden;
    margin-top: 450px;
    width: 50px; /* Breite des Pfeils */
    height: auto; /* Automatische Höhe */
    filter: invert(1); /* Um den Pfeil weiß zu machen, falls er schwarz ist */
  }
  
  /* Animation nur bis zur max-width von 870px */
  @media (max-width: 870px) {
    .arrow-icon {
        visibility: visible;
      animation: bounce 2s infinite; /* Animation anwenden */
    }
  }
  
  /* Bounce-Animation */
  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-20px);
    }
    60% {
      transform: translateY(-10px);
    }
  }
  