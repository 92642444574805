#suchergebnisse-wrapper {

    .card {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        border-color: var(--primary-color);
    }

    .card-body {
        padding: 20px;
    }

    .card-text {
        color: var(--text-color);
        margin-bottom: 20px;
        text-align: justify;
    }

    .btn-primary {
        background-color: var(--primary-color-transparent);
        /* oder eine Farbe deiner Wahl */
        border: none;
        border-radius: 5px;
        padding: 10px 20px;
    }

    .btn-primary:hover {
        background-color: var(--primary-color);
        /* dunklere Farbe für den Hover-Effekt */
    }
}