#standorte-seite-wrapper {

    p {
        font-size: 1.2rem;
        text-align: justify;
    }

    .card {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        border-color: var(--primary-color);
        padding: 1rem;
    }

    .img-fluid {
        width: 100%;
        height: 100%;
        object-fit: cover;
        background-position: center;
    }
}