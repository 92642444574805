.info-card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border: 1px solid var(--primary-color);
  padding: 20px;
  margin-top: 20px;
  background-color: var(--background-color);
  border-radius: 10px;
}

.rbc-calendar {
  padding-bottom: 2rem;
}

.rbc-toolbar .rbc-toolbar-label {
  padding-top: 1rem;
}


@media (max-width: 870px) {

  .rbc-calendar {
    min-height: 60vh;
  }

  .rbc-toolbar-label {
    padding: 2rem!important;
  }
}
