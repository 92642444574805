/* animations.css */
@keyframes pop-in {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.letter {
  display: inline-block;
  opacity: 0; /* Initial nicht sichtbar */
  transform: scale(0.5); /* Initiale Skalierung */
}

.letter.visible {
  animation: pop-in 0.5s ease forwards;
}
