/* === Navigationsleiste === */
.navbar {
    padding-top: 0;
    padding-bottom: 0;
    min-height: 100px;
    position: fixed;
    width: 100%;
    z-index: 10000;
    background-color: var(--background-secondary-color)!important;
}

.nav-link {
    border-bottom: 4px solid rgb(20, 20, 20, 0);
    transition: transform 0.3s ease, color 0.3s ease;
}

.nav-link:hover {
    color: var(--primary-color)!important;
    transform: translateY(5px);
}

.nav-link.active {
    color: var(--primary-color)!important;
}

.navbar-nav>li>a {
    font-size: 1.2rem;
    color: var(--text-color)!important;
}

.navbar-nav>li {
    padding-left: 1.8rem;
}

/* === Anfang Dropdown-Menü Standorte === */
.dropdown-menu {
    border: none;
    margin-left: 0.8rem;
    padding-right: 2rem;
    background-color: var(--background-secondary-color)!important;
}

.dropdown.active > a {
    color: var(--primary-color)!important;
}

.dropdown-item {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
    transition: transform 0.3s ease, color 0.3s ease;
    /* Füge eine sanfte Transition für transform und color hinzu */
    background-color: var(--background-secondary-color)!important;
}

.dropdown-item:hover {
    color: var(--primary-color);
    /* Ändert die Textfarbe beim Hover */
    transform: translateX(10px);
    /* Verschiebt den Text leicht nach rechts beim Hover */
    background-color: rgba(var(--bs-light-rgb));
}
.dropdown-item.active {
    color: var(--primary-color)!important;
}

.nav-item.dropdown {
    position: relative;
}

.dropdown-menu {
    top: 100%;
    /* Positioniere das Dropdown-Menü direkt unter dem Elternelement */
    left: 0;
    /* Linke Ausrichtung zum Elternelement */
}

/* === Ende Dropdown-Menü Standorte === */

.navbar-brand {
    font-size: 1.5rem;

    img {
        max-height: 65px;
    }
}

