.carousel-container {
    background-color: #333;
    /* Dunkler Hintergrund */
    max-width: 100%;

    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
    border-radius: 10px;
}

.carousel-item-padding-40-px {
    background-color: #444;
    /* Dunklerer Akzent für die einzelnen Slides */
    color: white;
    /* Weißer Text für bessere Lesbarkeit */
}

.carousel-item-padding-40-px img {
    /* Maximale Höhe des Bildes */
    min-width: 100%;
    max-height: 600px;
    min-height: 600px;
    overflow: hidden;
    object-fit: cover;
}

#carousel-wrapper .btn {
    background-color: var(--primary-color);
}

#carousel-wrapper .btn:disabled {
    background-color: var(--primary-color);
}

#carousel-wrapper .btn:any-link {
    background-color: var(--primary-color);
}

#carousel-wrapper .btn:hover {
    background-color: var(--primary-color);
}

.carousel-caption h4 {
    color: var(--text-color);
    padding: 50px 10px 50px 10px;
    background: rgba(255, 255, 255, 0.2);
    /* Weißer Hintergrund mit Transparenz */
    backdrop-filter: blur(10px);
    /* Blur-Effekt für den Hintergrund */
    -webkit-backdrop-filter: blur(10px);
    /* Unterstützung für Safari */
    border-radius: 15px;
}