#standorte-wrapper {

    p {
        font-size: 1.2rem;
        text-align: justify;
    }

    .card {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        min-height: 700px;
        border-color: var(--primary-color);
    }

    .card:hover {
        box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.2);
    }

    a {
        color: inherit;
        /* Erbt die Farbe vom Elternelement */
        text-decoration: none;
        /* Entfernt die Unterstreichung */
    }

    a:hover {
        color: inherit;
        /* Optional: behält die Farbe beim Hovern bei */
        text-decoration: none;
        /* Stellt sicher, dass keine Unterstreichung beim Hovern erscheint */
    }

    .card-img-top {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        height: 200px;
        /* oder 'auto'; abhängig von deinem Design */
        object-fit: cover;
        /* sorgt dafür, dass das Bild die Box ausfüllt ohne verzerrt zu werden */
    }

    .card-body {
        padding: 20px;
        background: linear-gradient(to bottom right, transparent 90%, var(--primary-color) 50%);
    }

    .card-text {
        color: var(--text-color);
        margin-bottom: 20px;
        text-align: left!important;
    }

    .btn-primary {
        background-color: var(--primary-color-transparent);
        /* oder eine Farbe deiner Wahl */
        border: none;
        border-radius: 5px;
        padding: 10px 20px;
    }

    .btn-primary:hover {
        background-color: var(--primary-color);
        /* dunklere Farbe für den Hover-Effekt */
    }
}