@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');


/* FARBEN */

:root {
    --primary-color: rgb(138, 43, 226);
    --primary-color-transparent: rgb(172, 96, 243);
    --secondary-color: rgb(221, 160, 221);
    --background-color: rgb(245, 245, 245);
    --background-secondary-color: rgb(240, 240, 240);
    --text-color: #333;
    /* Weitere Farben können hier hinzugefügt werden */
}




body {
    font-family: 'Roboto';
    background-color: var(--background-color);
    color: var(--text-color);
    text-align: justify;
}

p,
li {
    font-size: 1.4rem;
}

p {
    font-weight: lighter;
}


/* Extra large devices (large desktops, 1200px and up) */
@media (max-width: 1200px) {}

/* Large devices (desktops, 992px and up) */
@media (max-width: 992px) {
    .navbar {
        padding-bottom: 20px;
    }
}


/* Medium devices (tablets, 768px and up) */
@media (max-width: 768px) {
    .navbar {
        padding-bottom: 20px;
    }

    p,
    li {
        font-size: 1.2rem !important;
        ;
    }
}


/* Small devices (phones, 576px and up) */
@media (max-width: 576px) {
    .navbar {
        padding-bottom: 20px;
    }

    p,
    li {
        font-size: 1.2rem !important;
    }

    .rbc-calendar {
        height: 400px !important;
    }
}