/* BANNER STYLE*/

.banner {
    background-image: url("../img/01_Banner_Limbach_für_alle.webp");
    min-height: 75vh;
    background-size: cover;
    background-position: center;
}


#index-welcome {
    font-weight: lighter;
}

a:any-link {
    text-decoration: none;
    color: inherit;
}

#banner-foerdermittel {
    max-width: 100%;
    height: auto;

    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 10px;
}

@media (max-width: 1500px) {
    .banner {
        background-image: url("../img/Banner_Limbach_für_alle_Mobil.jpg");
    }
}

@media (max-width: 870px) {
    .banner {
        background-image: url("../img/Banner_Limbach_für_alle_mobile.jpg");
    }
}