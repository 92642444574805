#post-wrapper {
  img {
    object-fit: cover;
    padding: 1rem 1rem 1rem 1rem;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .btn-primary {
    background-color: var(--primary-color);
    /* oder eine Farbe deiner Wahl */
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
  }

  .btn-primary:hover {
    background-color: var(--primary-color-transparent);
    /* dunklere Farbe für den Hover-Effekt */
  }



  /* CSS für die mobile Ansicht */
  @media only screen and (max-width: 768px) {
    img {
      max-width: 100%;
      /* Bildbreite auf 100% des Container-Bereichs begrenzen */
      height: auto;
      /* Automatische Höhenanpassung, um das Bildverhältnis beizubehalten */
      float: none !important;
      /* Aufheben der Float-Eigenschaft für mobile Geräte */
    }


  }
}