#upload-event-wrapper {
    min-height: 100vh;


    .container {
        padding-top: 8rem;
    }
    #message-submit {
        padding-top: 5rem;
    }

    .form-group {
        margin-top: 1rem;
    }
}